import Request from "components/request/Request";
import { UploadProvider } from "contexts/uploadContext";
import React from "react";
import { useRouter } from "util/router.js";

export default function RequestPage() {
  const router = useRouter();

  return (
    <UploadProvider>
      <Request requestId={router.query.requestId} />
    </UploadProvider>
  );
}
