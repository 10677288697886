import { documentUploadService } from "services/documentUploadService";

// Create a new document
export async function createUploadUrl(
  linkShareId,
  vaultId,
  title,
  fileName,
  fileSizeBytes,
  contentType,
  folderId,
  token
) {
  const response = await documentUploadService.createUploadUrl(
    linkShareId,
    vaultId,
    title,
    fileName,
    fileSizeBytes,
    contentType,
    folderId,
    token
  );
  return response;
}
