import { addWithToken } from "./serviceConfig";

const API_BASE_URL = process.env.REACT_APP_LINK_SHARE_SVC_API_URL;

export const documentUploadService = {
  createUploadUrl(
    linkShareId,
    vaultId,
    title,
    fileName,
    fileSizeBytes,
    contentType,
    folderId,
    token
  ) {
    return addWithToken(
      `${API_BASE_URL}/linksharing/v1/linkshare/${linkShareId}/assets/${vaultId}/documents${
        !!folderId ? `?parentFolderId=${folderId}` : ""
      }`,
      {},
      {
        title: title,
        description: title,
        fileName: fileName,
        fileSizeBytes: fileSizeBytes,
        contentType: contentType,
        tags: [],
      },
      token
    );
  },
};
