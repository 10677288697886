import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { cacheKeys, sharedQueryClient } from "stores/storeConfig";
import { linksService } from "services/linkShareService";
import { addWithCookie } from "services/serviceConfig";

const queryClient = sharedQueryClient;
const shareCacheKey = cacheKeys.SHARE;
const linksCacheKey = cacheKeys.LINKS;
const assetsCacheKey = cacheKeys.ASSETS;
const downloadKey = cacheKeys.DOWNLOAD;
const webDownloadUrlKey = cacheKeys.WEBDOWNLOADURL;
const downloadUrlKey = cacheKeys.DOWNLOADURL;
const thumbnailKey = cacheKeys.THUMBNAIL;
const cookieKey = cacheKeys.COOKIE;

// Fetch all links (hook)
export function useAssets(shareId, token) {
  const cacheKey = [assetsCacheKey];
  const query = () => linksService.getAssets(shareId, token);
  return useQuery(cacheKey, query, {
    enabled: !!shareId && !!token,
  });
}

export function useShareCreator(shareId) {
  const cacheKey = [shareCacheKey];
  const query = () => linksService.getShareCreator(shareId);
  return useQuery(cacheKey, query, { enabled: !!shareId });
}

export function useThumbnail(shareId, assetId, token) {
  const cacheKey = [thumbnailKey, { assetId }];
  const query = () => linksService.getThumbnail(shareId, assetId, token);
  return useQuery(cacheKey, query);
}

export async function sendCode(shareId) {
  return await linksService.sendCode(shareId);
}

export async function verifyCode(shareId, code) {
  return await linksService.verifyCode(shareId, code);
}

export function useDocumentDownloadUrl(
  shareId,
  assetId,
  token,
  isEnabled,
  otherProps
) {
  const cacheKey = [downloadKey, { shareId, assetId }];
  const query = () => linksService.getDownloadUrl(shareId, assetId, token);
  return useQuery(cacheKey, query, {
    enabled: !!shareId && !!assetId && !!token && !!isEnabled,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,
    cacheTime: 0,
    ...otherProps,
  });
}

export async function useDocumentWebDownload(
  downloadUrl,
  shareId,
  assetId,
  token,
  isEnabled,
  { ...otherProps }
) {
  const cacheKey = [webDownloadUrlKey, { shareId, assetId }];
  const query = () =>
    linksService.downloadWebDocumentWithoutWindow(downloadUrl, token);
  return useQuery(cacheKey, query, {
    enabled: !!downloadUrl && !!shareId && !!token && !!isEnabled,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    ...otherProps,
  });
}

export async function useDocumentDownload(
  downloadUrl,
  shareId,
  assetId,
  isBase64,
  { ...otherProps }
) {
  const cacheKey = [downloadUrlKey, { shareId, assetId }];
  const query = () =>
    linksService.downloadDocument(downloadUrl + `?base64=${isBase64}`);
  return useQuery(cacheKey, query, {
    enabled: !!downloadUrl && !!shareId && !!assetId,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    ...otherProps,
  });
}

export function useDocumentDownloadData(shareId, assetId) {
  return sharedQueryClient.getQueryData([downloadUrlKey, { shareId, assetId }]);
}

export function useDownloadCookie(url, token, otherProps) {
  const cacheKey = [cookieKey, { url }];
  const query = () => linksService.getCookie(url, token);
  return useQuery(cacheKey, query, {
    enabled: !!url && !!token,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,
    cacheTime: 0,
    ...otherProps,
  });
}

export async function getDownload(cookie, downloadId) {
  console.log("getDocument");
  return await linksService.getDocument(cookie, downloadId);
}
