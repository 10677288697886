import React, { useState, createContext, useContext } from "react";

const UploadContext = createContext();

export function useUploadContext() {
  return useContext(UploadContext);
}

export const UploadProvider = ({ children }) => {
  const [showUploader, setShowUploader] = useState(false);
  const [files, setFiles] = useState(null);
  const [type, setType] = useState("document");
  const [assetId, setAssetId] = useState(null);
  const [folderId, setFolderId] = useState(null);
  const [limit, setLimit] = useState(25);
  const [linkShareId, setLinkShareId] = useState();
  const [token, setToken] = useState();
  //limitCount is used to determine if the uploader should prevent a file upload if limitCount + files.length > limit
  const [limitCount, setLimitCount] = useState();

  return (
    <UploadContext.Provider
      value={{
        showUploader,
        setShowUploader,
        files,
        setFiles,
        type,
        setType,
        assetId,
        setAssetId,
        folderId,
        setFolderId,
        limit,
        setLimit,
        limitCount,
        setLimitCount,
        linkShareId,
        setLinkShareId,
        token,
        setToken,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};
