import { useQuery } from "react-query";
import { cacheKeys, sharedQueryClient } from "stores/storeConfig";
import { storageService } from "services/storageService";

const queryClient = sharedQueryClient;
const documentsCacheKey = cacheKeys.DOCUMENTS;
const assetsCacheKey = cacheKeys.ASSETS;
const assetsCountKey = cacheKeys.ASSETCOUNTS;

// Create a new document
export async function createUploadUrl(fileName, fileSizeBytes, contentType) {
  const response = await storageService.createUploadUrl(
    fileName,
    fileSizeBytes,
    contentType
  );
  return response;
}

// Create a new document
export async function addDocument(
  uploadUrl,
  file,
  type,
  onUploadProgress,
  token
) {
  const response = await storageService.addDocument(
    uploadUrl,
    file,
    type,
    onUploadProgress,
    token
  );
  await Promise.all([
    queryClient.invalidateQueries([documentsCacheKey]),
    queryClient.invalidateQueries([assetsCacheKey]),
    queryClient.invalidateQueries([assetsCountKey]),
  ]);
  return response;
}

// Add a new document chunk
export async function addDocumentChunk(
  uploadUrl,
  file,
  type,
  onUploadProgress,
  token
) {
  const response = await storageService.addDocument(
    uploadUrl,
    file,
    type,
    onUploadProgress,
    token
  );
  return response;
}

// Get Start Large Document URL
export async function startLargeDocumentUpload(startUrl, token) {
  const response = await storageService.startLargeDocumentUpload(
    startUrl,
    token
  );
  return response;
}

// Get Document Download URL
export async function completeLargeDocumentUpload(completeUrl, token) {
  const response = await storageService.completeLargeDocumentUpload(
    completeUrl,
    token
  );
  await Promise.all([
    queryClient.invalidateQueries([documentsCacheKey]),
    queryClient.invalidateQueries([assetsCacheKey]),
    queryClient.invalidateQueries([assetsCountKey]),
  ]);
  return response;
}
