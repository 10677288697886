import React, { useState, useRef } from "react";
import PageLoader from "components/shared/pageloader/PageLoader";
import { useShareCreator, useAssets } from "stores/linkShareStore";
import Logo from "svgs/logo/Logo";
import styles from "./Request.module.scss";
import SendCode from "components/start/sendCode/SendCode";
import VerifyCode from "components/start/verifyCode/VerifyCode";
import InvalidLink from "components/start/invalidLink/InvalidLink";
import Button from "components/shared/button/Button";
import Footer from "components/shared/footer/Footer";
import Uploader from "components/shared/uploadprogress/Uploader";
import { useUploadContext } from "contexts/uploadContext";
import { removeFileExtension } from "util/helpers";
import FileUpload from "components/shared/FileUpload";
import cx from "classnames";
import Icon from "components/shared/icon/Icon";

export default function Request({ requestId }) {
  const [verifyCode, setVerifyCode] = useState(false);
  const { data: meta, error, isLoading, isIdle } = useShareCreator(requestId);
  const {
    setShowUploader,
    setFiles,
    setType,
    setFolderId,
    setLinkShareId,
    setAssetId,
    setToken,
    token,
  } = useUploadContext();
  const addDocumentsRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);

  const { data: assets } = useAssets(requestId, token);

  function handleVerifySuccess(accessToken) {
    setToken(accessToken);
  }

  function handleSendCode() {
    setVerifyCode(true);
  }

  function updateUploadedFiles(files) {
    const newUploads = files.map((f) => {
      const file = f;
      const id = Math.random().toString(16).slice(2);
      const documentOriginalFileName = f.name;
      const title = removeFileExtension(f.name);
      const type = f.type;
      const totalChunkProgress = [];
      return {
        file,
        id,
        documentOriginalFileName,
        title,
        type,
        progress: -1,
        totalChunkProgress,
      };
    });
    setFiles(newUploads);
    setLinkShareId(requestId);
    setAssetId(assets?.data?.vaultId);
    setType("document");
    setShowUploader(true);
    setFolderId(null);
  }

  function handleUploadClick() {
    setFiles([]);
    setShowUploader(false);
    addDocumentsRef.current.click();
  }

  //DRAG / DROP UPLOAD FROM DESKTOP
  function handleDrag(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
      setShowUploader(false);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }

  //Triggers when file is dropped
  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      let files = [];
      //e.dataTransfer.files formats file upload array objects differently than click to upload.  This just cleans it up by removing the keys.
      for (const [key, value] of Object.entries(e.dataTransfer.files)) {
        files.push(value);
      }
      updateUploadedFiles(files);
    }
  }

  function renderUpload() {
    return (
      <div className={styles.content} onDragEnter={handleDrag}>
        <section className={cx(styles.background)}>
          <div className="container is-flex is-flex-direction-column is-align-items-center">
            <div className={styles.headerWrapper}>
              <div className={error && styles.logoWrapper}>
                <Logo className={styles.logo} showText />
              </div>
            </div>
            <div className={styles.panel}>
              <h2>{meta.data.creatorName} has requested documents.</h2>

              <div
                className={cx(styles.box, {
                  [styles.dragActive]: dragActive,
                })}
              >
                <div className="is-hidden">
                  <FileUpload
                    accept="*"
                    label="Add Documents"
                    parentRef={addDocumentsRef}
                    multiple
                    updateFilesCb={updateUploadedFiles}
                  />
                </div>

                <div className={styles.uploadDescription}>
                  <div>
                    <Icon size="xlarge" type="cloud_upload" color="info" />
                  </div>
                  <h3 className={styles.uploadHeader}>
                    Drag and drop your files here
                  </h3>
                  <Button
                    text="Browse Files"
                    color="secondary"
                    id="upload-documents-btn"
                    onClick={() => {
                      //trackClick("Add Documents");
                      handleUploadClick();
                    }}
                  />
                  <p className="mt-3">Max file size: 90MB</p>
                </div>
              </div>
              <p className={styles.info}>
                <span>
                  Your files will be securely uploaded to{" "}
                  {meta.data.creatorName}'s vault.
                </span>
                <a
                  className="ml-1 mr-2"
                  href="https://help.prisid.io/documents"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Learn more.
                </a>
              </p>
              <div className={styles.uploader}>
                <Uploader />
              </div>
            </div>
          </div>
        </section>
        <Footer />
        {dragActive && (
          <div
            className={styles.dragFileElement}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </div>
    );
  }

  function renderRequest() {
    return (
      <section className={styles.background}>
        <div className="container">
          <div className={styles.headerWrapper}>
            <div className={error && styles.logoWrapper}>
              <Logo className={styles.logo} showText />
            </div>
          </div>
          {!requestId ? (
            <InvalidLink />
          ) : isLoading || isIdle ? (
            <PageLoader />
          ) : meta?.data ? (
            verifyCode ? (
              <VerifyCode
                shareId={requestId}
                onSuccess={handleVerifySuccess}
                isRequest
              />
            ) : (
              <SendCode
                creatorName={meta.data.creatorName}
                shareId={requestId}
                onSuccess={handleSendCode}
                isRequest
              />
            )
          ) : (
            <InvalidLink />
          )}
        </div>
      </section>
    );
  }

  return <>{token ? renderUpload() : renderRequest()}</>;
}

Request.propTypes = {};
