import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "../Start.module.scss";
import Button from "components/shared/button/Button";
import { useMutation } from "react-query";
import { sendCode, verifyCode } from "stores/linkShareStore";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import FormField from "components/shared/formfield/FormField";
import TextLink from "components/shared/link/TextLink";

export default function VerifyCode({ shareId, onSuccess, isRequest }) {
  const { handleSubmit, register, errors, getValues } = useForm();

  const [buttonText, setButtonText] = useState("Access Files");

  useEffect(() => {
    if (isRequest) setButtonText("Access Upload");
  }, []);

  const { mutate: resendCode, isLoading: resendLoading } = useMutation(
    () => {
      return sendCode(shareId);
    },
    {
      onSuccess: () => {
        onSuccess();
        toast.success("Successfully resent verification code.");
      },
      onError: (error) => {
        toast.error("There was an error verifying the confirmation code.");
      },
    }
  );

  const { mutate: verify, isLoading: verifyLoading } = useMutation(
    (code) => {
      return verifyCode(shareId, code);
    },
    {
      onSuccess: (response) => {
        onSuccess(response.data.token);
      },
      onError: (error) => {
        toast.error("Invalid code, please try again.");
      },
    }
  );

  function handleResendCode() {
    resendCode();
  }

  function onSubmit(data) {
    verify(data.code);
  }

  return (
    <div className={styles.metaWrapper}>
      <p className={styles.metaText}>
        Enter the code sent to your email address.
      </p>
      <p className={styles.securityText}>The code will expire in 15 minutes.</p>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormField
          autoFocus
          hasMargin={false}
          name="code"
          type="text"
          size="normal"
          label="Confirmation Code"
          id="person-invite-email"
          error={errors.code}
          inputRef={register({
            required: "Please enter confirmation code",
          })}
        />
        <div className={styles.accessFilesButtonWrapper}>
          <Button
            text={buttonText}
            isFullWidth
            onClick={handleSubmit(onSubmit)}
            disabled={resendLoading || verifyLoading}
            isLoading={verifyLoading}
          />
        </div>
      </form>

      <p
        className={
          resendLoading ? styles.sendCodeButtonDisabled : styles.sendCodeButton
        }
        onClick={resendLoading ? null : () => handleResendCode()}
      >
        Send Another Code
      </p>
    </div>
  );
}

VerifyCode.propTypes = {
  shareId: PropTypes.string,
  onSuccess: PropTypes.func,
};
