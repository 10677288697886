import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useUploadContext } from "contexts/uploadContext";
import { UploadProgress } from "./UploadProgress";

function Uploader({}) {
  const {
    showUploader,
    setShowUploader,
    files,
    setFiles,
    type,
    linkShareId,
    assetId,
    folderId,
    limit,
    limitCount,
    token,
  } = useUploadContext();

  function closeUploadProgress() {
    setFiles([]);
    setShowUploader(false);
  }

  return (
    <>
      {showUploader && (
        <UploadProgress
          isVisible={showUploader}
          onClose={() => closeUploadProgress()}
          files={files}
          type={type}
          linkShareId={linkShareId}
          assetId={assetId}
          folderId={folderId}
          limit={limit}
          limitCount={limitCount}
          token={token}
        />
      )}
    </>
  );
}

Uploader.propTypes = {};

export default Uploader;
