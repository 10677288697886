import React from "react";
import PropTypes from "prop-types";
import Icon from "../icon/Icon";
import styles from "./Card.module.scss";
import { history } from "util/router";
import CircleIcon from "../icon/CircleIcon";
import cx from "classnames";

export default function Card({
  icon,
  title,
  subtitle,
  splitSubtitle,
  description,
  selected,
  clickable,
  type = "document",
  url,
  imageUrl,
  isLoading,
  dropdown,
  name,
  status,
  role,
  showRadioButton,
  showDropdown,
  showStatus,
  assetId,
  helpMenuRef,
  ...otherProps
}) {
  function DocumentCard() {
    return (
      <div
        onClick={(e) => {
          history.push(url);
        }}
        className={cx(`card ${styles.cardDoc}`)}
        {...otherProps}
      >
        {type === "folder" ? (
          <Icon type="folder" size="lg" />
        ) : (
          <div
            className={cx(`card-image ${styles.cardImage}`, {
              [styles.cardAvatar]: !imageUrl,
            })}
          >
            <figure className="image">
              {imageUrl ? (
                <img src={imageUrl} alt={title} />
              ) : (
                <CircleIcon icon="insert_drive_file" size="xxlarge" />
              )}
            </figure>
          </div>
        )}
        {dropdown}
        <footer className={`card-footer ${styles.cardFooter}`}>
          <span className={styles.cardTitle}>{title}</span>
          <span className={cx("material-icons-outlined", styles.icon)}>
            {icon}
          </span>
        </footer>
      </div>
    );
  }

  return <DocumentCard />;
}

Card.propTypes = {
  /**
   * The main title of your card.  Typically a Document name, Folder name or Person name.
   */
  title: PropTypes.string.isRequired,
  /**
   * The subtitle of your card.
   */
  subtitle: PropTypes.string,
  /**
   * The type of card you wish to display.
   */
  type: PropTypes.oneOf([
    "document",
    "folder",
    "person",
    "place",
    "organization",
    "placeholder",
    "simple",
    "marketing",
    "help",
  ]),
  /**
   * The main image displayed on the card.
   */
  imageUrl: PropTypes.string,
};
