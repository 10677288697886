import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "../Start.module.scss";
import Button from "components/shared/button/Button";
import { useMutation } from "react-query";
import { sendCode } from "stores/linkShareStore";
import { toast } from "react-toastify";

export default function SendCode({
  creatorName,
  shareId,
  onSuccess,
  isRequest,
}) {
  const [metaText, setMetaText] = useState(
    `${creatorName} has shared files with you.`
  );
  const [securityText, setSecurityText] = useState(
    "Security is important to us. To access the files click the button below and we will send a confirmation code to your email."
  );

  useEffect(() => {
    if (isRequest) {
      setMetaText(`${creatorName} has requested files.`);
      setSecurityText(
        "Security is important to us. To upload files click the button below and we will send a confirmation code to your email."
      );
    }
  }, []);

  const { mutate, isLoading } = useMutation(
    () => {
      return sendCode(shareId);
    },
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: (error) => {
        toast.error("There was an error sending the confirmation code");
      },
    }
  );

  function handleSendClick() {
    mutate();
  }

  return (
    <div className={styles.metaWrapper}>
      <p className={styles.metaText}>{metaText}</p>
      <p className={styles.securityText}>{securityText}</p>
      <div className={styles.sendCodeButtonWrapper}>
        <Button
          text="Send Code"
          isFullWidth
          onClick={handleSendClick}
          disabled={isLoading}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

SendCode.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onSendCode: PropTypes.func,
};
