import React, { useEffect, useState } from "react";
import PageLoader from "components/shared/pageloader/PageLoader";
import { useShareCreator } from "stores/linkShareStore";
import Logo from "svgs/logo/Logo";
import styles from "./Start.module.scss";
import SendCode from "./sendCode/SendCode";
import VerifyCode from "./verifyCode/VerifyCode";
import InvalidLink from "./invalidLink/InvalidLink";
import Assets from "./assets/Assets";
import imageSrc from "assets/Thats_Prisidio.png";

export default function Start({ shareId }) {
  const [token, setToken] = useState();
  const [verifyCode, setVerifyCode] = useState(false);
  const { data: meta, error, isLoading, isIdle } = useShareCreator(shareId);

  function handleVerifySuccess(accessToken) {
    setToken(accessToken);
  }

  function handleSendCode() {
    setVerifyCode(true);
  }

  function renderDownload() {
    return (
      <section className={styles.background}>
        <div className="container">
          <div className={styles.headerWrapper}>
            <div className={error && styles.logoWrapper}>
              <Logo className={styles.logo} showText />
            </div>
          </div>

          <Assets
            shareId={shareId}
            token={token}
            creatorName={meta?.data?.creatorName}
            expirationTime={meta?.data?.expirationTime}
          />
        </div>
      </section>
    );
  }

  function renderStart() {
    return (
      <section className={styles.background}>
        <div className="container">
          <div className={styles.headerWrapper}>
            <div className={error && styles.logoWrapper}>
              <Logo className={styles.logo} showText />
            </div>
          </div>
          {!shareId ? (
            <InvalidLink />
          ) : isLoading || isIdle ? (
            <PageLoader />
          ) : meta?.data ? (
            verifyCode ? (
              <VerifyCode shareId={shareId} onSuccess={handleVerifySuccess} />
            ) : (
              <SendCode
                creatorName={meta.data.creatorName}
                shareId={shareId}
                onSuccess={handleSendCode}
              />
            )
          ) : (
            <InvalidLink />
          )}
        </div>
      </section>
    );
  }

  return (
    <>
      {token ? renderDownload() : renderStart()}
      <img
        src={imageSrc}
        alt="That's Prisidio"
        className={styles.footerImage}
      />
    </>
  );
}

Start.propTypes = {};
