import React, { useState, useEffect } from "react";
import styles from "./UploadProgress.module.scss";
import cx from "classnames";
import { UploadProgressItem } from "./UploadProgressItem";
import { sharedQueryClient } from "stores/storeConfig";
import { pluralize } from "util/helpers";

function InternalUploadProgress({
  files,
  type,
  linkShareId,
  assetId,
  folderId,
  isVisible,
  onClose,
  limit,
  limitCount,
  token,
}) {
  const [uploads, setUploads] = useState(files || []);
  const [failures, setFailures] = useState([]);
  const [successIds, setSuccessIds] = useState([]);
  const [errorIds, setErrorIds] = useState([]);

  function close() {
    onClose();
  }

  useEffect(() => {
    if (uploads.length === successIds.length && !errorIds.length) {
      setTimeout(() => {
        onClose();
      }, 15000);
    }
  }, [uploads, successIds, errorIds]);

  function uploadsComplete() {
    return uploads.length === successIds.length + errorIds.length;
  }

  function handleUploadSuccess(id) {
    successIds.push(id);
    setSuccessIds([...successIds]);
  }

  function handleUploadError(id, error) {
    const failedUpload = uploads.find((u) => u.id === id);
    if (!failedUpload) return;

    failedUpload.error = error;
    failures.push(failedUpload);
    setFailures([...failures]);

    errorIds.push(id);
    setErrorIds([...errorIds]);
  }

  function handleUploadRemove(id) {
    const newFailures = failures ? failures.filter((f) => f.id !== id) : [];
    setFailures([...newFailures]);
  }

  function handleLimitError(index) {
    return type === "media" && limitCount + index > limit;
  }

  return (
    <div
      className={cx(styles.uploadProgress, styles.progressOffset, {
        [styles.isVisible]: isVisible,
      })}
    >
      <div className={cx(styles.uploadHeader, "p-3")}>
        <div className={styles.uploadHeaderStart}>
          <h3>
            {!uploadsComplete()
              ? `Uploading ${uploads.length} ${pluralize(
                  "File",
                  uploads.length
                )}...`
              : "Upload Complete"}
          </h3>
        </div>
        {/* <div className={styles.uploadHeaderEnd}>
          <span className="icon" onClick={() => close()}>
            <span className="material-icons-outlined">close</span>
          </span>
        </div> */}
      </div>
      <div className={styles.uploadBody}>
        {uploads.map((upload, index) => (
          <UploadProgressItem
            key={upload.id}
            file={upload}
            onSuccess={handleUploadSuccess}
            onError={handleUploadError}
            onRemove={handleUploadRemove}
            type={type}
            linkShareId={linkShareId}
            assetId={assetId}
            folderId={folderId}
            limitError={handleLimitError(index)}
            token={token}
          />
        ))}
      </div>
    </div>
  );
}

export const UploadProgress = React.memo(InternalUploadProgress);
