import React, { useState, useEffect } from "react";
import styles from "./UploadProgress.module.scss";
import cx from "classnames";
import {
  middleEllipsis,
  removeFileExtension,
  getFileExtension,
  sumArrayValues,
} from "util/helpers";
import { createUploadUrl } from "stores/documentUploadStore";
import {
  addDocument,
  addDocumentChunk,
  completeLargeDocumentUpload,
  startLargeDocumentUpload,
} from "stores/storageStore";
// import { useVaultsContext } from "contexts/vaultsContext";
import { supportedFileTypesArr } from "data/fileTypes";
// import { trackUpload } from "util/analytics";

const FiftyMBinBytes = 52428800;
const TwoHundredFiftyMBinBytes = 262144000;

function InternalUploadProgressItem({
  file,
  type,
  linkShareId,
  assetId,
  folderId,
  onSuccess,
  onError,
  onRemove,
  limitError,
  token,
}) {
  const [complete, setComplete] = useState(false);
  const [progress, setProgress] = useState(-1);
  const [multiProgress, setMultiProgress] = useState([]);
  const [error, setError] = useState(file.error);
  const [uploadType, setUploadType] = useState(type);
  //const { activeVaultId } = useVaultsContext();
  const activeVaultId = "12345";

  useEffect(() => {
    if (file.file.size === 0) {
      const error = "Upload failed. File has no data.";
      setError(error);
      setComplete(true);
      onError(file.id, error);
    } else if (
      type === "document" &&
      !supportedFileTypesArr[0].includes(
        getFileExtension(file.file.name)[0].toLowerCase()
      )
    ) {
      const error = "Upload failed. File type not supported.";
      setError(error);
      setComplete(true);
      onError(file.id, error);
    } else if (limitError) {
      const error = "Upload failed. You've met the file limit.";
      setError(error);
      setComplete(true);
      onError(file.id, error);
    } else {
      !complete && type === "document" && uploadDocument();
    }

    //trackUpload(file.type, file.file.size);
  }, []);

  function retryUpload() {
    uploadDocument(true);
  }

  function handleProgressUpdate(progress) {
    setProgress(progress);
  }

  function handleMultipartProgressUpdate(chunk, progress) {
    const percentage = Math.floor((progress.loaded * 100) / progress.total);
    multiProgress[chunk] = percentage;
    setMultiProgress([...multiProgress]);
    const totalProgress =
      multiProgress.reduce(sumArrayValues) / multiProgress.length;

    handleProgressUpdate(totalProgress);
  }

  function handleSinglepartProgressUpdate(progress) {
    const percentage = Math.floor((progress.loaded * 100) / progress.total);

    handleProgressUpdate(percentage);
  }

  async function uploadDocument(isRetry = false) {
    setError(null);

    const multipartPromises = [];

    createUploadUrl(
      linkShareId,
      assetId,
      removeFileExtension(file.file.name),
      file.file.name,
      file.file.size,
      file.type,
      folderId,
      token
    )
      .then((res) => {
        if (res.data.upload.type === "multi") {
          for (let i = 0; i < res.data.upload.multi.chunks.length; i++) {
            multiProgress.push(0);
          }
          setMultiProgress([...multiProgress]);

          //gotta hit res.data.upload.startUrl
          startLargeDocumentUpload(res.data.upload.multi.startUrl, token)
            .then(async (startRes) => {
              //wait for it to come back
              //on success - post to each chunk

              for (let i = 0; i < res.data.upload.multi.chunks.length; i++) {
                //slice the file based on bite offset
                //can be done in parallel
                let fileChunk = file.file.slice(
                  parseInt(res.data.upload.multi.chunks[i].byteOffset),
                  parseInt(res.data.upload.multi.chunks[i].byteOffset) +
                    parseInt(res.data.upload.multi.chunks[i].bytes)
                );
                multipartPromises.push(
                  await addDocumentChunk(
                    res.data.upload.multi.chunks[i].uploadUrl,
                    fileChunk,
                    file.file.type,
                    (progress) => handleMultipartProgressUpdate(i, progress),
                    token
                  )
                    .then((res) => {
                      //chunk upload success, we don't need to do anything.
                    })
                    .catch((error) => {
                      console.log("chunk upload error", error);
                    })
                );
              }
              Promise.all(multipartPromises)
                .then(() => {
                  completeLargeDocumentUpload(
                    res.data.upload.multi.completeUrl,
                    token
                  )
                    .then((completeRes) => {
                      setComplete(true);
                      isRetry ? onRemove(file.id) : onSuccess(file.id);
                    })
                    .catch((error) => {
                      const message = "Upload failed. Please try again.";
                      setError(message);
                      onError(file.id, message);
                    });
                })
                .catch((e) => {
                  console.log("error", e);
                  const message = "Upload failed. Please try again.";
                  setError(message);
                  onError(file.id, message);
                });
            })
            .catch((error) => {
              console.log("error", error);
            });
        } else {
          addDocument(
            res.data.upload.single.uploadUrl,
            file.file,
            file.file.type,
            handleSinglepartProgressUpdate,
            //folderId,
            token
          )
            .then((response) => {
              setComplete(true);
              isRetry ? onRemove(file.id) : onSuccess(file.id);
            })
            .catch(() => {
              const message = "Upload failed. Please try again.";
              setError(message);
              onError(file.id, message);
            });
        }
      })
      .catch(() => {
        const message = "Upload failed. Please try again.";
        setError(message);
        onError(file.id, message);
      });
  }

  return (
    <div className={styles.uploadItemRow} key={file.id}>
      <div className={styles.rowIcon}>
        <span className="icon-text">
          <span className="icon">
            <span className="material-icons-outlined">insert_drive_file</span>
          </span>
        </span>
      </div>
      <div className={styles.rowStart}>
        <div className={styles.title}>{middleEllipsis(file.title, 40)}</div>
        {progress === -1 && !complete && !error && (
          <span className={styles.subTitle}>Waiting...</span>
        )}
        {progress !== -1 && !complete && !error && (
          <div className={styles.progress}>
            <div className={styles.progressBar}>
              <div
                style={{
                  width: !!progress ? `${progress}%` : "0%",
                }}
              ></div>
            </div>
          </div>
        )}
        {error && (
          <span className={`${styles.progressText} has-text-danger`}>
            {error}
          </span>
        )}
      </div>
      <div className={styles.rowEnd}>
        {progress === 100 && !complete && !error && (
          <span className={`loader is-loading ${styles.largeLoader}`} />
        )}
        {complete && !error && (
          <span className="material-icons-outlined is-flex">check_circle</span>
        )}
        {error && (
          <span className="material-icons-outlined is-flex has-text-danger">
            error_outline
          </span>
        )}
      </div>
    </div>
  );
}

export const UploadProgressItem = React.memo(InternalUploadProgressItem);
