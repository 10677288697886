import React from "react";
import "styles/global.scss";
import "styles/animation.scss";
import { Switch, Route, Router } from "util/router.js";
import { QueryClientProvider } from "stores/storeConfig";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WithAxios from "auth/WithAxios";
import StartPage from "pages/start";
import RequestPage from "pages/request";

const fade = cssTransition({
  enter: "fade-in-top",
  exit: "fade-out-top",
});

const App = () => {
  return (
    <QueryClientProvider>
      {/* //Unauthed page(s) */}
      <Router>
        <Switch>
          <Route
            path={["/", "/linkshare", "/linkshare/:guid"]}
            exact
            component={StartPage}
          />
          <Route path={["/request/:requestId"]} exact component={RequestPage} />
        </Switch>
      </Router>
      <WithAxios>
        <Router></Router>
        <ReactQueryDevtools initialIsOpen={false} />
        <ToastContainer
          position="top-center"
          autoClose={4000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeButton={false}
          icon={false}
          transition={fade}
          className="toastify-notification"
        />
      </WithAxios>
    </QueryClientProvider>
  );
};

export default App;
