import {
  get,
  add,
  getWithToken,
  addWithToken,
  getImageWithToken,
  downloadWithoutWindow,
  getWithCookie,
  addWithTokenAndCredentials,
} from "./serviceConfig";
const API_BASE_URL = process.env.REACT_APP_LINK_SHARE_SVC_API_URL;

export const linksService = {
  getAssets(shareId, token) {
    return getWithToken(
      `${API_BASE_URL}/linksharing/v1/linkshare/${shareId}/assets`,
      {},
      token
    );
  },

  getShareCreator(shareId) {
    return get(`${API_BASE_URL}/linksharing/v1/linkshare/${shareId}/creator`);
  },

  sendCode(shareId) {
    return get(
      `${API_BASE_URL}/linksharing/v1/linkshare/${shareId}/challenge/send`
    );
  },

  verifyCode(shareId, code) {
    return add(
      `${API_BASE_URL}/linksharing/v1/linkshare/${shareId}/challenge/verify`,
      {},
      { code: code }
    );
  },

  getDownloadUrl(shareId, assetId, token) {
    return addWithToken(
      `${API_BASE_URL}/linksharing/v1/linkshare/${shareId}/assets/${assetId}/downloads`,
      {},
      null,
      token
    );
  },

  downloadWebDocumentWithoutWindow(downloadUrl, token) {
    return downloadWithoutWindow(downloadUrl);
  },

  downloadDocument(downloadUrl) {
    return get(downloadUrl);
  },

  getThumbnail(shareId, assetId, token) {
    return getImageWithToken(
      `${API_BASE_URL}/linksharing/v1/linkshare/${shareId}/assets/${assetId}/thumbnail`,
      {},
      token
    );
  },

  getCookie(url, token) {
    return addWithTokenAndCredentials(url, {}, null, token);
  },

  getDocument(cookie, downloadId) {
    return getWithCookie(
      `${API_BASE_URL}/linksharing/v1/linkshare/downloads/${downloadId}`,
      {},
      cookie
    );
  },
};
