export function middleEllipsis(
  str,
  lengthCheck = 25,
  startCount = 10,
  endCount = 10
) {
  if (!str) return "";
  if (str.length > lengthCheck) {
    return (
      str.substr(0, startCount) +
      "..." +
      str.substr(str.length - endCount, str.length)
    );
  }
  return str;
}

export function removeIdFromString(str) {
  return str.replace(/[0-9]+/g, "id");
}

export function pluralize(word, count, suffix = "s") {
  return count > 1 ? `${word}${suffix}` : word;
}

export function formatFileSize(bytes) {
  if (bytes === 0) return "0 Bytes";
  const k = 1000,
    dm = 0,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function removeFileExtension(fileName) {
  return fileName.replace(/\.[^/.]+$/, "");
}

export function getFileExtension(fileName) {
  return fileName.match(/\.[0-9a-z]+$/i);
}

export function getFileTypeFromContentType(contentType) {
  return contentType.split("/")[1];
}

export function getFileTypeDisplay(fullContentType) {
  const WORD_CONTENT_TYPES = [
    "msword",
    "vnd.openxmlformats-officedocument.wordprocessingml.document",
    "vnd.ms-word.template.macroEnabled.12",
    "vnd.openxmlformats-officedocument.wordprocessingml.template",
  ];
  const POWERPOINT_CONTENT_TYPES = [
    "vnd.openxmlformats-officedocument.presentationml.template",
    "vnd.ms-powerpoint",
    "vnd.ms-powerpoint.presentation.macroEnabled.12",
    "vnd.openxmlformats-officedocument.presentationml.presentation",
  ];
  const EXCEL_CONTENT_TYPES = [
    "vnd.ms-excel",
    "vnd.ms-excel.sheet.macroEnabled.12",
    "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const contentType = fullContentType.split("/")[1];

  if (WORD_CONTENT_TYPES.includes(contentType)) {
    return "WORD";
  } else if (POWERPOINT_CONTENT_TYPES.includes(contentType)) {
    return "POWERPOINT";
  } else if (EXCEL_CONTENT_TYPES.includes(contentType)) {
    return "EXCEL";
  } else {
    return contentType.toUpperCase();
  }
}

export const sumArrayValues = (previousValue, currentValue) =>
  previousValue + currentValue;
