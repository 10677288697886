import { useState, useMemo, useEffect } from "react";
import axios from "axios";

export default function WithAxios({ children }) {
  const [token, setToken] = useState(null);

  useEffect(() => {
    async function getToken() {
      //GET AND APPLY YOUR TOKEN HERE
      setToken(token);
    }
    getToken();
  }, []);

  useMemo(() => {
    axios.interceptors.request.use(async (config) => {
      if (!token) return config;
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  }, [token]);

  return children;
}
