import React, { useRef } from "react";
import { supportedFileTypes } from "data/fileTypes";

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

export default function FileUpload({
  label,
  updateFilesCb,
  parentRef,
  ...otherProps
}) {
  const fileInputField = useRef(null);

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    const files = {};
    for (let file of newFiles) {
      if (!otherProps.multiple) {
        return { file };
      }
      files[file.name] = file;
    }
    return { ...files };
  };

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  return (
    <>
      <section>
        <a
          className="dropdown-item p-3 hidden"
          onClick={handleUploadBtnClick}
          ref={parentRef}
          id="add-documents"
        >
          <span className="icon-text">
            <span className="icon">
              <span className="material-icons-outlined">insert_drive_file</span>
            </span>
            <span className="is-size-6 pl-2">Add Documents</span>
          </span>
        </a>
        <input
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          {...otherProps}
          accept={supportedFileTypes}
          className="is-hidden"
        />
      </section>
    </>
  );
}
