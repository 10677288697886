import React, { useEffect, useState } from "react";
import Card from "components/shared/card/Card";
import { middleEllipsis } from "util/helpers";
import styles from "../Assets.module.scss";
import {
  useDocumentDownloadUrl,
  useDownloadCookie,
  useThumbnail,
} from "stores/linkShareStore";
import { isMobile } from "react-device-detect";

export default function AssetsGrid({ shareId, asset, token }) {
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
  const [userInitiatedDownloadUrl, setUserInitiatedDownloadUrl] = useState("");
  const [downloadUrl, setDownloadUrl] = useState();
  const [hasCookie, setHasCookie] = useState(false);
  const [base64Thumbnail, setBase64Thumbnail] = useState();

  const { data: thumbnail } = useThumbnail(shareId, asset?.assetId, token);

  useEffect(() => {
    if (!thumbnail?.data) return;

    const base64 = `data:${thumbnail.data.contentType};base64,${thumbnail.data.data}`;
    setBase64Thumbnail(base64);
  }, [thumbnail?.data]);

  const { data: documentDownloadUrl, error: documentDownloadUrlError } =
    useDocumentDownloadUrl(shareId, asset?.assetId, token, isDownloadEnabled, {
      onSettled: () => {
        setIsDownloadEnabled(false);
      },
    });

  useEffect(() => {
    if (!documentDownloadUrl?.data) return;

    const downloadUrl = documentDownloadUrl.data.single.webDownloadUrl;
    setDownloadUrl(downloadUrl);
  }, [documentDownloadUrl?.data]);

  const { data: cookieResponse, error: cookieError } = useDownloadCookie(
    downloadUrl,
    token
  );

  useEffect(() => {
    if (!cookieResponse) return;

    if (cookieResponse?.status === 201) {
      setHasCookie(true);
    }
  }, [cookieResponse?.data]);

  useEffect(() => {
    if (!hasCookie) return;

    if (hasCookie && !!downloadUrl) {
      initiateDownload();
    }
  }, [hasCookie, downloadUrl]);

  // async function initiateDownload() {
  //   if (isMobile) {
  //     let response = await fetch(
  //       `${process.env.REACT_APP_LINK_SHARE_SVC_API_URL}/linksharing/v1/linkshare/downloads/${downloadId}`
  //     );
  //     let data = await response.blob();
  //     let metadata = {
  //       type: "application/pdf",
  //     };
  //     const pdfFile = new File([data], `${asset.name}.pdf`, metadata);

  //     if (navigator.canShare({ files: [pdfFile] })) {
  //       navigator.share({
  //         files: [pdfFile],
  //       });
  //     }
  //   } else {
  //     setUserInitiatedDownloadUrl(
  //       `${process.env.REACT_APP_LINK_SHARE_SVC_API_URL}/linksharing/v1/linkshare/downloads/${downloadId}`
  //     );

  //     setTimeout(() => {
  //       setIsDownloadEnabled(false);
  //       setUserInitiatedDownloadUrl(null);
  //       setCookieHeader(null);
  //     }, 2000);
  //   }
  // }

  async function initiateDownload() {
    setUserInitiatedDownloadUrl(downloadUrl);

    setTimeout(() => {
      setIsDownloadEnabled(false);
      setUserInitiatedDownloadUrl(null);
      setHasCookie(false);
    }, 5000);
  }

  function handleDownload() {
    setIsDownloadEnabled(true);
  }

  return (
    <div className="column is-narrow">
      <Card
        title={middleEllipsis(asset.name, 24)}
        subtitle=""
        type="document"
        id="asset-card"
        icon="cloud_download"
        imageUrl={base64Thumbnail}
        onClick={!isDownloadEnabled ? () => handleDownload() : null}
      />
      {userInitiatedDownloadUrl && (
        <iframe
          title={asset?.assetId}
          src={userInitiatedDownloadUrl}
          className={styles.iframe}
        ></iframe>
      )}
    </div>
  );
}
