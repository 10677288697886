import React from "react";
import PropTypes from "prop-types";
import styles from "./Footer.module.scss";

export default function Footer({}) {
  return (
    <div className={styles.footer}>
      <span>{`©${new Date().getFullYear()} Prisidio. All rights reserved.`}</span>
      <a
        className="ml-4 mr-2"
        href="https://www.prisid.io/privacy-policy"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Privacy Policy
      </a>
      <a
        className="ml-4 mr-2"
        href="https://www.prisid.io/terms-of-service"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Terms of Service
      </a>
    </div>
  );
}

Footer.propTypes = {};
