import { add, addWithToken, upload } from "./serviceConfig";
const API_BASE_URL = process.env.REACT_APP_STORAGE_SVC_API_URL;
const SVC_BASE_URL = process.env.REACT_APP_STORAGE_SVC_URL;

export const storageService = {
  createUploadUrl(fileName, fileSizeBytes, contentType) {
    return add(
      `https://api-storage-svc.vault.dev-local.prisidio.net/storageX/v1/uploads`,
      {},
      {
        fileName: fileName,
        fileSizeBytes: fileSizeBytes,
        contentType: contentType,
        authorizationId: "tempvalue",
      }
    );
  },

  startLargeDocumentUpload(startUrl, token) {
    return addWithToken(startUrl, {}, {}, token);
  },
  completeLargeDocumentUpload(completeUrl, token) {
    return addWithToken(completeUrl, {}, {}, token);
  },

  addDocument(uploadUrl, file, type, onUploadProgress, token) {
    return upload(uploadUrl, file, {
      headers: {
        "Content-Type": type,
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress,
    });
  },
};
