import React, { useEffect, useState } from "react";
import PageLoader from "components/shared/pageloader/PageLoader";
import { useAssets } from "stores/linkShareStore";
import AssetsGrid from "./layout/Grid";
import { history } from "util/router";
import { toast } from "react-toastify";
import NoLongerAvailable from "../noLongerAvailable/NoLongerAvailable";
import styles from "../Start.module.scss";
import moment from "moment";
require("moment-timezone");

export default function Assets({
  shareId,
  token,
  creatorName,
  expirationTime,
}) {
  const { data: assets, error, isLoading, isIdle } = useAssets(shareId, token);

  useEffect(() => {
    if (error) {
      toast.error(
        "There was an error accessing the documents. Please try again."
      );
      history.go(0);
    }
  }, [error]);

  function renderAssetsContent() {
    return isLoading || isIdle ? (
      <PageLoader />
    ) : assets?.data && assets?.data?.assets?.length ? (
      <div className={styles.metaWrapper}>
        <p className={styles.sharedMetaText}>
          {creatorName} shared files with you.
        </p>
        <p className={styles.securityText}>
          This link expires on{" "}
          {moment
            .utc(assets?.data?.expirationTime * 1000)
            .local()
            .format("MMMM, DD YYYY [at] h:mm:ssa ")}
          {moment.tz(moment.tz?.guess()).zoneAbbr()}
        </p>

        <div className="columns is-multiline is-centered mt-2">
          {assets.data.assets.map((asset, index) => (
            <AssetsGrid
              shareId={shareId}
              asset={asset}
              key={asset.assetId}
              token={token}
            />
          ))}
        </div>
      </div>
    ) : (
      <div className="container">
        <NoLongerAvailable creatorName={creatorName} />
      </div>
    );
  }

  return <section>{renderAssetsContent()}</section>;
}

Assets.propTypes = {};
