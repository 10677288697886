import React from "react";
import PropTypes from "prop-types";
import styles from "../Start.module.scss";
import Button from "components/shared/button/Button";

export default function InvalidLink() {
  return (
    <div className={styles.metaWrapper}>
      <div className={styles.errorWrapper}>
        <p className={styles.errorHeader}>This link is no longer valid</p>
        <p className={styles.subText}>
          Please contact the person who sent you the link with any questions.
        </p>
        <p className={styles.keepOrganizedHeader}>Keep life organized™</p>
        <p className={styles.subText}>
          Prisidio is a life management solution to easily store, organize, and
          share all of your valuable personal documents and information in one
          safe and convenient location. Your life is valuable, keep it
          organized.
        </p>
        <div className={styles.learnMoreButtonWrapper}>
          <Button
            text="Learn More"
            isFullWidth
            onClick={() => {
              window.open("https://prisid.io");
            }}
          />
        </div>
      </div>
    </div>
  );
}

InvalidLink.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onSendCode: PropTypes.func,
};
